export default {
	name: 'AuctionLanding',
	metaInfo() {
		return {
			title: `${this.$t('Авто аукціон від AUTO.RIA24 - умови для участі і правила проведення аукціонів в Україні')}`,
			meta: [
				{name: 'description', content: this.$t('Дізнайтесь про аукціони авто на AUTO.RIA. Детальна інформація про умови для участі, правила проведення аукціонів та важливу термінологію')},
				{property: 'og:site_name', content: 'AUTO.RIA'},
				{property: 'og:title', content: this.$t('Головне про аукціони AUTO.RIA 24')},
				{property: 'og:description', content: this.$t('Все про аукціони AUTO.RIA 24: основні терміни, умови участі, правила аукціону')},
				{property: 'og:image', content: 'https://css.riastatic.com/images/og-placeholder.png'},
				{name: 'ROBOTS', content: 'INDEX, FOLLOW'},
			],
			link: [
				{rel: 'alternate', hreflang: 'ru-UA', href: 'https://auto.ria.com/auction-rules/'},
				{rel: 'alternate', hreflang: 'uk-UA', href: 'https://auto.ria.com/uk/auction-rules/'},
				{rel: 'alternate', hreflang: 'ru', href: 'https://auto.ria.com/auction-rules/'},
				{rel: 'alternate', hreflang: 'uk', href: 'https://auto.ria.com/uk/auction-rules/'},
				{rel: 'alternate', hreflang: 'x-default', href: 'https://auto.ria.com/auction-rules/'},
				{rel: 'canonical', href: `https://auto.ria.com${this.langPrefix || ''}/auction-rules/`}
			]
		};
	},
	i18n: {
		messages: {
			ru: {
				'Аукціон': 'Аукцион',
				'Оптимальне рішення для продавців і покупців авто': 'Оптимальное решение для продавцов и покупателей авто',
				'Гарантія якості після огляду на СТО': 'Гарантия качества после осмотра на СТО',
				'Швидкий продаж': 'Быстрая продажа',
				'Вигідна ціна': 'Выгодная цена',
				'Як продати авто': 'Как продать авто',
				'Як купити авто': 'Как купить авто',
				'з аукціону?': 'с аукциона?',
				'При додаванні авто увімкніть можливість': 'При добавлении авто включите возможность',
				'розмістити його на аукціоні': 'разместить его на аукционе',
				'Менеджер AUTO.RIA зв’яжеться з вами': 'Менеджер AUTO.RIA свяжется с вами',
				'й організує': 'и организует',
				'безкоштовну': 'бесплатную',
				'перевірку на СТО. Після оцінки вартості авто буде виставлене на аукціон': 'проверку на СТО. После оценки стоимости авто будет выставлено на аукцион',
				'Аукціон доступний': 'Аукцион доступен',
				'для користувачів AUTO.RIA 24 години, а безпосередньо торг — 2 години': 'для пользователей AUTO.RIA 24 часа, а непосредственно торг — 2 часа',
				'Після продажу': 'После продажи',
				'ви отримаєте суму': 'вы получите сумму',
				'яка на 100% відповідає розміру виграшної ставки': 'которая на 100% соответствует размеру выигрышной ставки',
				'Перевірка технічного стану авто на партнерських СТО AUTO.RIA обов’язкова та безкоштовна для продавця': 'Проверка технического состояния авто на партнерских СТО AUTO.RIA обязательна и бесплатна для продавца',
				'Ви маєте право відмовитись від продажу, якщо сума виграшної ставки не відповідає вашим очікуванням': 'Вы имеете право отказаться от продажи, если сумма выигрышной ставки не соответствует вашим ожиданиям',
				'Якщо з аукціону авто не продається, при подальшому продажі на AUTO.RIA звіт з СТО обов’язково додається до пропозиції': 'Если с аукциона авто не продается, при последующей продаже на AUTO.RIA отчет с СТО обязательно прилагается к предложению',
				'Зареєструйтесь': 'Зарегистрируйтесь',
				'та переконайтесь, що на Особистому рахунку достатньо коштів для': 'и убедитесь, что на Личном счете достаточно средств для',
				'Аукціонного збору': 'Аукционного сбора',
				'Оберіть авто': 'Выберите авто',
				'серед майбутніх та активних аукціонів. За потреби увімкніть сповіщення про початок торгів': 'среди будущих и активных аукционов. При необходимости включите оповещения о начале торгов',
				'Стежте за ставками': 'Следите за ставками',
				'покупців та зробіть свою': 'покупателей и сделайте свою',
				'мінімальний крок': 'минимальный шаг',
				'Відмінити ставку не можна — тільки підвищити': 'Отменить ставку нельзя — только повысить',
				'Виграйте аукціон': 'Выиграйте аукцион',
				'та зустріньтесь із продавцем для купівлі авто': 'и встретьтесь с продавцом для покупки авто',
				'Після заявки на участь в торгах на вашому Особистому рахунку повинні бути 3 500 грн для оплати Аукціонного збору': 'После заявки на участие в торгах на вашем Личном счету должно быть 3 500 грн для оплаты Аукционного сбора',
				'при виграші аукціону': 'при выигрыше аукциона',
				'якщо ваша ставка — найвища': 'если ваша ставка — самая высокая',
				'Не сплачується, якщо ціну переб’є інший покупець, і сплачується, якщо ви виграли аукціон, але відмовляєтесь від купівлі': 'Не оплачивается, если цену перебьет другой покупатель, и оплачивается, если вы выиграли аукцион, но отказываетесь от покупки',
				'після придбання авто': 'после покупки авто',
				'Не сплачується, якщо ви відмовляєтесь від купівлі після зустрічі з покупцем': 'Не оплачивается, если вы отказываетесь от покупки после встречи с покупателем',
				'Словник термінів': 'Словарь терминов',
				'Що таке аукціон?': 'Что такое аукцион?',
				'Аукціон — продаж автомобіля покупцеві, який у ході публічних торгів запропонує найвищу ціну.': 'Аукцион — продажа автомобиля покупателю, который в ходе публичных торгов предложит самую высокую цену.',
				'Всі учасники торгу бачать ставки, які пропонують покупці. Якщо хтось пропонує більш вигідну ціну,': 'Все участники торга видят ставки, которые предлагают покупатели. Если кто-то предлагает более выгодную цену,',
				'ви можете підняти свою. Мінімальний крок – 50$.': 'вы можете поднять свою. Минимальный шаг — 50 $.',
				'Що таке ставка?': 'Что такое ставка?',
				'Ставка — ціна, яку ви готові заплатити за купівлю авто. Роблячи ставку, ви тим самим даєте знати продавцю': 'Ставка — цена, которую вы готовы заплатить за покупку авто. Делая ставку, вы тем самым даете знать продавцу',
				'та іншим учасникам торгу про намір придбати авто за суму, яка відповідає розміру ставки.': 'и другим участникам торга о намерении приобрести авто за сумму, которая соответствует размеру ставки.',
				'Як зробити ставку?': 'Как сделать ставку?',
				'На сторінці авто, що продається з аукціону, є блок «Ваша ставка», де вказаний розмір можливої ставки.': 'На странице авто, которое продается с аукциона, есть блок «Ваша ставка», где указан размер возможной ставки.',
				'За допомогою кнопок «+» і «-» збільшіть чи зменшіть суму, яку готові заплатити продавцеві. Коли ставка буде сформована, натисніть кнопку «Зробити ставку»': 'С помощью кнопок «+» и «-» увеличьте или уменьшите сумму, которую готовы заплатить продавцу. Когда ставка будет сформирована, нажмите кнопку «Сделать ставку»',
				'Як відмінити ставку?': 'Как отменить ставку?',
				'Зроблену ставку неможливо відмінити, її можуть лише перебити інші покупці.': 'Сделанную ставку невозможно отменить, ее могут только перебить другие покупатели.',
				'Що таке Аукціонний збір?': 'Что такое Аукционный сбор?',
				'Аукціонний збір це сума, яка сплачується з Особистого рахунку покупця для оплати комісій після успішного': 'Аукционный сбор это сумма, которая оплачивается с Личного счета покупателя для оплаты комиссий после успешного',
				'проходження етапів торгівлі: 500 грн при виграші аукціону, якщо його ставка найвища, і 3 000 грн після придбання авто.': 'прохождения этапов торговли: 500 грн при выигрыше аукциона, если его ставка самая высокая, и 3 000 грн после покупки авто.',
				'Ця комісія стягується додатково до ціни авто.': 'Эта комиссия взимается дополнительно к цене авто.',
				'Що таке виграшна ставка?': 'Что такое выигрышная ставка?',
				'Це найвища ставка, яка була зроблена до завершення часу аукціону.': 'Это самая высокая ставка, которая была сделана до завершения времени аукциона.',
				'Якщо ставка зроблена в останні 2 хвилини аукціону, тоді він продовжується на 2 хвилини. По завершенні аукціону виграшна ставка пропонується продавцю, а покупець, який її зробив, купує автомобіль.': 'Если ставка сделана в последние 2 минуты аукциона, тогда он продлевается на 2 минуты. По завершении аукциона выигрышная ставка предлагается продавцу, а покупатель, который ее сделал, покупает автомобиль.',
				'А якщо мене не влаштовує ціна, яка виграла аукціон': 'А если меня не устраивает цена, которая выиграла аукцион',
				'Ви — продавець і завжди можете відмовитись від продажу без пояснення причин. Якщо фінальна ставка не відповідає вашим очікуванням, ви можете розмістити свою пропозицію для звичайного продажу на AUTO.RIA': 'Вы — продавец и всегда можете отказаться от продажи без объяснения причин. Если финальная ставка не соответствует вашим ожиданиям, тогда вы можете разместить свое предложение для обычной продажи на AUTO.RIA',
				'Мене не влаштовує звіт з СТО': 'Меня не устраивает отчет с СТО',
				'Звіт СТО — обов’язкова умова для продажу авто з аукціону. Слід розуміти, що покупці купують авто без огляду і їм потрібні гарантії, що його стан відповідає оголошеній вартості. Тому авто без даних технічної перевірки не допускається до участі в аукціоні': 'Отчет СТО — обязательное условие для продажи авто с аукциона. Следует понимать, что покупатели покупают авто без осмотра и им нужны гарантии, что его состояние соответствует объявленной стоимости. Поэтому авто без данных технической проверки не допускается к участию в аукционе',
				'Я не згоден з оцінкою авто після технічної перевірки на СТО': 'Я не согласен с оценкой авто после технической проверки на СТО',
				'Стартова ціна торгів, яка встановлюється за результатами перевірки на СТО, і справді може бути нижчою за бажану — це поширена світова практика. Але ця сума лише попередня й ні до чого вас не зобов’язує — з кожною ставкою ціна авто буде зростати, а фінальна ставка (сума, за яку авто в підсумку продається) часто навіть вигідніша, ніж розраховують продавці': 'Стартовая цена торгов, которая устанавливается по результатам проверки на СТО, действительно может быть ниже желаемой — это распространенная мировая практика. Но эта сумма только предварительная и ни к чему вас не обязывает — с каждой ставкой цена авто будет расти, а финальная ставка (сумма, за которую авто в итоге продается) часто даже выгоднее, чем рассчитывают продавцы',
				'Якщо моя ставка виграла аукціон, а я передумав купувати авто': 'Если моя ставка выиграла аукцион, а я передумал покупать авто',
				'В такому випадку із замороженої для участі в аукціоні суми буде списана комісія 500 грн. Право купівлі авто при цьому перейде до покупця, чия ставка друга за величиною після вашої': 'В таком случае с замороженной для участия в аукционе суммы будет списана комиссия 500 грн. Право покупки авто при этом перейдет к покупателю, чья ставка вторая по величине после вашей',
				'Лишились питання': 'Остались вопросы',
				'Зв’яжіться з нами, ми все пояснимо': 'Свяжитесь с нами, мы все объясним',
				'Дмитро': 'Дмитрий',
				'Авто аукціон від AUTO.RIA24 - умови для участі і правила проведення аукціонів в Україні': 'Авто аукцион от AUTO.RIA24 - условия для участия и правила проведения аукционов в Украине',
				'Головне про аукціони AUTO.RIA 24': 'Главное об аукционах AUTO.RIA 24',
				'Дізнайтесь про аукціони авто на AUTO.RIA. Детальна інформація про умови для участі, правила проведення аукціонів та важливу термінологію': 'Узнайте все об аукционах авто на AUTO.RIA. Детальная информация про условия для участия, правила проведения аукционов и важную терминологию',
				'Все про аукціони AUTO.RIA 24: основні терміни, умови участі, правила аукціону': 'Всё об аукционах AUTO.RIA 24: основные термины, условия участия, правила аукциона',
			},
			uk: {
				'Аукціон': 'Аукціон',
				'Оптимальне рішення для продавців і покупців авто': 'Оптимальне рішення для продавців і покупців авто',
				'Гарантія якості після огляду на СТО': 'Гарантія якості після огляду на СТО',
				'Швидкий продаж': 'Швидкий продаж',
				'Вигідна ціна': 'Вигідна ціна',
				'Як продати авто': 'Як продати авто ',
				'Як купити авто': 'Як купити авто ',
				'з аукціону?': 'з аукціону?',
				'При додаванні авто увімкніть можливість': 'При додаванні авто увімкніть можливість',
				'розмістити його на аукціоні': 'розмістити його на аукціоні',
				'Менеджер AUTO.RIA зв’яжеться з вами': 'Менеджер AUTO.RIA зв’яжеться з вами',
				'й організує': 'й організує',
				'безкоштовну': 'безкоштовну',
				'перевірку на СТО. Після оцінки вартості авто буде виставлене на аукціон': 'перевірку на СТО. Після оцінки вартості авто буде виставлене на аукціон',
				'Аукціон доступний': 'Аукціон доступний',
				'для користувачів AUTO.RIA 24 години, а безпосередньо торг — 2 години': '',
				'Після продажу': 'Після продажу',
				'ви отримаєте суму': 'ви отримаєте суму',
				'яка на 100% відповідає розміру виграшної ставки': 'яка на 100% відповідає розміру виграшної ставки',
				'Перевірка технічного стану авто на партнерських СТО AUTO.RIA обов’язкова та безкоштовна для продавця': 'Перевірка технічного стану авто на партнерських СТО AUTO.RIA обов’язкова та безкоштовна для продавця',
				'Ви маєте право відмовитись від продажу, якщо сума виграшної ставки не відповідає вашим очікуванням': 'Ви маєте право відмовитись від продажу, якщо сума виграшної ставки не відповідає вашим очікуванням',
				'Якщо з аукціону авто не продається, при подальшому продажі на AUTO.RIA звіт з СТО обов’язково додається до пропозиції': 'Якщо з аукціону авто не продається, при подальшому продажі на AUTO.RIA звіт з СТО обов’язково додається до пропозиції',
				'Зареєструйтесь': 'Зареєструйтесь',
				'та переконайтесь, що на Особистому рахунку достатньо коштів для': 'та переконайтесь, що на Особистому рахунку достатньо коштів для',
				'Аукціонного збору': 'Аукціонного збору',
				'Оберіть авто': 'Оберіть авто',
				'серед майбутніх та активних аукціонів. За потреби увімкніть сповіщення про початок торгів': 'серед майбутніх та активних аукціонів. За потреби увімкніть сповіщення про початок торгів',
				'Стежте за ставками': 'Стежте за ставками',
				'покупців та зробіть свою': 'покупців та зробіть свою',
				'мінімальний крок': 'мінімальний крок',
				'Відмінити ставку не можна — тільки підвищити': 'Відмінити ставку не можна — тільки підвищити',
				'Виграйте аукціон': 'Виграйте аукціон',
				'та зустріньтесь із продавцем для купівлі авто': 'та зустріньтесь із продавцем для купівлі авто',
				'Після заявки на участь в торгах на вашому Особистому рахунку повинні бути 3 500 грн для оплати Аукціонного збору': 'Після заявки на участь в торгах на вашому Особистому рахунку повинні бути 3 500 грн для оплати Аукціонного збору',
				'при виграші аукціону': 'при виграші аукціону',
				'якщо ваша ставка — найвища': 'якщо ваша ставка — найвища',
				'Не сплачується, якщо ціну переб’є інший покупець, і сплачується, якщо ви виграли аукціон, але відмовляєтесь від купівлі': 'Не сплачується, якщо ціну переб’є інший покупець, і сплачується, якщо ви виграли аукціон, але відмовляєтесь від купівлі',
				'після придбання авто': 'після придбання авто',
				'Не сплачується, якщо ви відмовляєтесь від купівлі після зустрічі з покупцем': 'Не сплачується, якщо ви відмовляєтесь від купівлі після зустрічі з покупцем',
				'Словник термінів': 'Словник термінів',
				'Що таке аукціон?': 'Що таке аукціон?',
				'Аукціон — продаж автомобіля покупцеві, який у ході публічних торгів запропонує найвищу ціну.': 'Аукціон — продаж автомобіля покупцеві, який у ході публічних торгів запропонує найвищу ціну.',
				'Всі учасники торгу бачать ставки, які пропонують покупці. Якщо хтось пропонує більш вигідну ціну,': 'Всі учасники торгу бачать ставки, які пропонують покупці. Якщо хтось пропонує більш вигідну ціну,',
				'ви можете підняти свою. Мінімальний крок – 50$.': 'ви можете підняти свою. Мінімальний крок – 50$.',
				'Що таке ставка?': 'Що таке ставка?',
				'Ставка — ціна, яку ви готові заплатити за купівлю авто. Роблячи ставку, ви тим самим даєте знати продавцю': 'Ставка — ціна, яку ви готові заплатити за купівлю авто. Роблячи ставку, ви тим самим даєте знати продавцю',
				'та іншим учасникам торгу про намір придбати авто за суму, яка відповідає розміру ставки.': 'та іншим учасникам торгу про намір придбати авто за суму, яка відповідає розміру ставки.',
				'Як зробити ставку?': 'Як зробити ставку?',
				'На сторінці авто, що продається з аукціону, є блок «Ваша ставка», де вказаний розмір можливої ставки.': 'На сторінці авто, що продається з аукціону, є блок «Ваша ставка», де вказаний розмір можливої ставки.',
				'За допомогою кнопок «+» і «-» збільшіть чи зменшіть суму, яку готові заплатити продавцеві. Коли ставка буде сформована, натисніть кнопку «Зробити ставку»': 'За допомогою кнопок «+» і «-» збільшіть чи зменшіть суму, яку готові заплатити продавцеві. Коли ставка буде сформована, натисніть кнопку «Зробити ставку»',
				'Як відмінити ставку?': 'Як відмінити ставку?',
				'Зроблену ставку неможливо відмінити, її можуть лише перебити інші покупці.': 'Зроблену ставку неможливо відмінити, її можуть лише перебити інші покупці.',
				'Що таке Аукціонний збір?': 'Що таке Аукціонний збір?',
				'Аукціонний збір це сума, яка сплачується з Особистого рахунку покупця для оплати комісій після успішного': 'Аукціонний збір це сума, яка сплачується з Особистого рахунку покупця для оплати комісій після успішного',
				'проходження етапів торгівлі: 500 грн при виграші аукціону, якщо його ставка найвища, і 3 000 грн після придбання авто.': 'проходження етапів торгівлі: 500 грн при виграші аукціону, якщо його ставка найвища, і 3 000 грн після придбання авто.',
				'Ця комісія стягується додатково до ціни авто.': 'Ця комісія стягується додатково до ціни авто.',
				'Що таке виграшна ставка?': 'Що таке виграшна ставка?',
				'Це найвища ставка, яка була зроблена до завершення часу аукціону.': 'Це найвища ставка, яка була зроблена до завершення часу аукціону.',
				'Якщо ставка зроблена в останні 2 хвилини аукціону, тоді він продовжується на 2 хвилини. По завершенні аукціону виграшна ставка пропонується продавцю, а покупець, який її зробив, купує автомобіль.': 'Якщо ставка зроблена в останні 2 хвилини аукціону, тоді він продовжується на 2 хвилини. По завершенні аукціону виграшна ставка пропонується продавцю, а покупець, який її зробив, купує автомобіль.',
				'А якщо мене не влаштовує ціна, яка виграла аукціон': 'А якщо мене не влаштовує ціна, яка виграла аукціон',
				'Ви — продавець і завжди можете відмовитись від продажу без пояснення причин. Якщо фінальна ставка не відповідає вашим очікуванням, ви можете розмістити свою пропозицію для звичайного продажу на AUTO.RIA': 'Ви — продавець і завжди можете відмовитись від продажу без пояснення причин. Якщо фінальна ставка не відповідає вашим очікуванням, ви можете розмістити свою пропозицію для звичайного продажу на AUTO.RIA',
				'Мене не влаштовує звіт з СТО': 'Мене не влаштовує звіт з СТО',
				'Звіт СТО — обов’язкова умова для продажу авто з аукціону. Слід розуміти, що покупці купують авто без огляду і їм потрібні гарантії, що його стан відповідає оголошеній вартості. Тому авто без даних технічної перевірки не допускається до участі в аукціоні': 'Звіт СТО — обов’язкова умова для продажу авто з аукціону. Слід розуміти, що покупці купують авто без огляду і їм потрібні гарантії, що його стан відповідає оголошеній вартості. Тому авто без даних технічної перевірки не допускається до участі в аукціоні',
				'Я не згоден з оцінкою авто після технічної перевірки на СТО': 'Я не згоден з оцінкою авто після технічної перевірки на СТО',
				'Стартова ціна торгів, яка встановлюється за результатами перевірки на СТО, і справді може бути нижчою за бажану — це поширена світова практика. Але ця сума лише попередня й ні до чого вас не зобов’язує — з кожною ставкою ціна авто буде зростати, а фінальна ставка (сума, за яку авто в підсумку продається) часто навіть вигідніша, ніж розраховують продавці': 'Стартова ціна торгів, яка встановлюється за результатами перевірки на СТО, і справді може бути нижчою за бажану — це поширена світова практика. Але ця сума лише попередня й ні до чого вас не зобов’язує — з кожною ставкою ціна авто буде зростати, а фінальна ставка (сума, за яку авто в підсумку продається) часто навіть вигідніша, ніж розраховують продавці',
				'Якщо моя ставка виграла аукціон, а я передумав купувати авто': 'Якщо моя ставка виграла аукціон, а я передумав купувати авто',
				'В такому випадку із замороженої для участі в аукціоні суми буде списана комісія 500 грн. Право купівлі авто при цьому перейде до покупця, чия ставка друга за величиною після вашої': 'В такому випадку із замороженої для участі в аукціоні суми буде списана комісія 500 грн. Право купівлі авто при цьому перейде до покупця, чия ставка друга за величиною після вашої',
				'Лишились питання': 'Лишились питання',
				'Зв’яжіться з нами, ми все пояснимо': 'Зв’яжіться з нами, ми все пояснимо',
				'Дмитро': 'Дмитро',
				'Авто аукціон від AUTO.RIA24 - умови для участі і правила проведення аукціонів в Україні': 'Авто аукціон від AUTO.RIA24 - умови для участі і правила проведення аукціонів в Україні',
				'Головне про аукціони AUTO.RIA 24': 'Головне про аукціони AUTO.RIA 24',
				'Дізнайтесь про аукціони авто на AUTO.RIA. Детальна інформація про умови для участі, правила проведення аукціонів та важливу термінологію': 'Дізнайтесь про аукціони авто на AUTO.RIA. Детальна інформація про умови для участі, правила проведення аукціонів та важливу термінологію',
				'Все про аукціони AUTO.RIA 24: основні терміни, умови участі, правила аукціону': 'Все про аукціони AUTO.RIA 24: основні терміни, умови участі, правила аукціону',
			}
		}
	}
};
